<template>
  <Modal
    :is-visible="isAddedToCartDialogOpen"
    class="overflow-y-auto"
    :show-close-button="false"
    @close="emit('update:is-added-to-cart-dialog-open', false)"
  >
    <div class="p-5">
      <div
        class="mb-4 flex flex-row content-center items-center justify-center"
      >
        <h2 class="text-center text-2xl text-supporting-black-87">
          {{ $t('subscriptions.added_to_cart_modal.title') }}
        </h2>
        <button
          data-test-id="cart-button"
          :aria-label="$t('cart.show_cart')"
          class="size-13 absolute right-4 py-4 pr-5 min-[380px]:pl-4 md:p-4"
          @click="
            () => {
              emit('update:is-added-to-cart-dialog-open', false);
              toggleSidebar();
            }
          "
        >
          <CartPictogram
            :animate-on-update="true"
            :cart-amount="itemCount"
            size-class="w-6 h-6"
            cart-amount-class="text-white text-xs md:text-base"
          />
        </button>
      </div>
      <hr />

      <ProductHeader
        :title="product.name"
        :path="product.path"
        :image="image.src"
        :brand="product.brand"
        :animal-type="animalName"
        :tracking-context-data="{
          position: 1,
          list: 'Added to cart ',
        }"
        :frequency-in-days="subscriptionFrequencyInDays"
        :enable-quantity-selector="false"
        :show-quantity="false"
        class="mt-1"
        :show-border="false"
      />
      <Button
        color="primary"
        class="mt-8 w-full"
        data-test-id="added-to-cart-continue-shopping-button"
        @click="emit('update:is-added-to-cart-dialog-open', false)"
      >
        {{ $t('subscriptions.added_to_cart_modal.continue_shopping') }}
      </Button>
      <Button v-if="false"></Button>
      <LoginBankIdButton
        v-if="
          enabledFeaturesStore.isFeatureEnabled('subscriptions') &&
          !isLoggedIn &&
          subscriptionFrequencyInDays > 0
        "
        redirect-page="shop-checkout"
        label="cart.login_to_checkout"
        class="mb-2 mt-4 w-full"
        color="transparentOutlined"
      >
      </LoginBankIdButton>
      <Button
        v-else
        data-test-id="go-to-checkout-button"
        color="transparentOutlined"
        class="mb-2 mt-4 w-full text-primary-default"
        :link="localePath({ name: 'shop-checkout' })"
      >
        {{ $t('subscriptions.added_to_cart_modal.to_checkout') }}
      </Button>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import type { Product as GqlProduct } from '#root/shared/types/graphql-types';
import { toggleBodyScroll } from '@/helpers/utils';

const enabledFeaturesStore = useEnabledFeaturesStore();
const authStore = useAuthStore();
const cartStore = useCartStore();
const sidebarStore = useSidebarStore();

const localePath = useLocalePath();

const { isLoggedIn } = storeToRefs(authStore);
const { itemCount } = storeToRefs(cartStore);

const props = withDefaults(
  defineProps<{
    isAddedToCartDialogOpen: boolean;
    product: Pick<GqlProduct, 'name' | 'brand' | 'animalCategories' | 'path'>;
    image: { src: string; alt?: string };
    subscriptionFrequencyInDays: number;
  }>(),
  {
    isAddedToCartDialogOpen: false,
    product: () => ({
      name: '',
      brand: {
        name: '',
      } as GqlProduct['brand'],
      animalCategories: [] as GqlProduct['animalCategories'],
      path: '',
    }),
    image: () => ({ src: '', alt: '' }),
    subscriptionFrequencyInDays: 0,
  }
);

const animalName = computed(
  () => props.product?.animalCategories?.[0]?.node?.animal || ''
);

const toggleSidebar = (open?: boolean) => sidebarStore.toggleSidebar(open);

const emit = defineEmits(['update:is-added-to-cart-dialog-open']);

watch(
  () => props.isAddedToCartDialogOpen,
  (visible) => {
    if (process.client) {
      toggleBodyScroll(!visible);
    }

    emit('update:is-added-to-cart-dialog-open', visible);
  },
  { immediate: true }
);

onUnmounted(() => {
  if (process.client) {
    toggleBodyScroll(true);
  }
});
</script>
